<template>
    <el-scrollbar class="main-wrapper">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin-left: 20px; line-height: 42px;">
            <el-breadcrumb-item>商品</el-breadcrumb-item>
            <el-breadcrumb-item>添加商品</el-breadcrumb-item>
            <el-breadcrumb-item>商品类别</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="big-title">普通发品</div>
        <div class="main-content">
            <div class="history-wrapper">
                <span class="text">最近使用的类目</span>
                <el-select v-model="historyClassValue" placeholder="" style="width: 420px"
                           @change="changeHistoryClass">
                    <el-option v-for="(historyItem, historyIndex) in historyClassList" :key="`historyClassList_${historyIndex}`"
                               :label="historyItem.label" :value="historyItem.value"></el-option>
                </el-select>
            </div>
            <div class="category-wrapper">
                <div class="search-wrapper" v-clickoutside="hideSearchBox">
                    <el-input placeholder="可输入类目关键字进行搜索" @keyup.enter.native="searchBtn" clearable
                              prefix-icon="el-icon-search" v-model="searchValue" style="width: 350px"></el-input>
                    <el-scrollbar class="search-list" v-if="showSearchBox">
                        <div class="text-overflow search-item"
                             @click="changeSearchClass(searchItem.label, searchItem.value)"
                             :title="searchItem.label"
                             v-for="(searchItem, searchIndex) in searchList"
                             :key="`searchList_${searchIndex}`">{{searchItem.label}}</div>
                    </el-scrollbar>
                </div>

                <div class="category-level">
                    <el-scrollbar class="level-item">
                        <div class="cate-item" @click="changeLevel(cateItem, 1)"
                             :ref="`level_1_${cateItem.id}`"
                             :class="class_id1 === cateItem.id ? 'current' : ''"
                             v-for="(cateItem, cateIndex) in categoryList"
                             :key="`categoryList_${cateIndex}`">
                            <span class="text-overflow item-left">{{cateItem.name}}</span>
                            <i class="iconfont" v-if="cateItem.hasOwnProperty('children') && cateItem.children.length > 0">&#xe602;</i>
                        </div>
                    </el-scrollbar>
                    <el-scrollbar class="level-item">
                        <div class="cate-item" @click="changeLevel(cateItem, 2)"
                             :ref="`level_2_${cateItem.id}`"
                             :class="class_id2 === cateItem.id ? 'current' : ''"
                             v-for="(cateItem, cateIndex) in level2CategoryList"
                             :key="`level2CategoryList_${cateIndex}`">
                            <span class="text-overflow item-left">{{cateItem.name}}</span>
                            <i class="iconfont" v-if="cateItem.hasOwnProperty('children') && cateItem.children.length > 0">&#xe602;</i>
                        </div>
                    </el-scrollbar>
                    <el-scrollbar class="level-item">
                        <div class="cate-item" @click="changeLevel(cateItem, 3)"
                             :ref="`level_3_${cateItem.id}`"
                             :class="class_id3 === cateItem.id ? 'current' : ''"
                             v-for="(cateItem, cateIndex) in level3CategoryList"
                             :key="`level3CategoryList_${cateIndex}`">
                            <span class="text-overflow item-left">{{cateItem.name}}</span>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="category-choose">
                    <span class="text">您当前的选择是：</span>
                    <span class="value" v-if="class_name1">{{class_name1}}</span>
                    <span class="value" v-if="class_name2">{{class_name2}}</span>
                    <span class="value" v-if="class_name3">{{class_name3}}</span>
                </div>
            </div>
            <el-button type="primary" @click="nextBtn">下一步，填写商品信息</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    import clickoutside from '@/utils/clickoutside.js'
    import { adminGoodRecentlyUsedCategory, adminGoodGetCategory, adminGoodStepOne, stuOpStuOpGoodsDetail } from '@/utils/apis.js'
    export default {
        name:'GoodsCategory',
        directives: {
            clickoutside
        },
        data() {
            return {
                examModuleId: Number(this.$route.query.exam_module_id) || '',
                goodId: Number(this.$route.query.g_id) || null,
                historyClassValue: '',
                historyClassList: [],
                showSearchBox: false,
                searchValue: '',
                searchList: [],
                class_id1: null,
                categoryList: [],
                class_id2: null,
                level2CategoryList: [],
                class_id3: null,
                level3CategoryList: [],
                class_name1: '',
                class_name2: '',
                class_name3: '',
            }
        },
        mounted() {
            this.getHistoryClassList()
            this.getCategoryList()
            if (this.goodId) {
                this.getInfo()
            }
        },
        methods: {
            getInfo() {
                let params = {
                    id: this.goodId
                }
                if (this.$route.query.id) {
                    params.op_id = this.$route.query.id;
                }
                stuOpStuOpGoodsDetail(params).then((res) => {
                    this.class_id1 = res.data.goods_category_id[0]
                    this.class_id2 = res.data.goods_category_id[2]
                    this.class_id3 = res.data.goods_category_id[4]
                    this.class_name1 = res.data.goods_category_id[1]
                    this.class_name2 = res.data.goods_category_id[3]
                    this.class_name3 = res.data.goods_category_id[5]
                })
            },
            // 获取最近使用的类目
            getHistoryClassList() {
                adminGoodRecentlyUsedCategory().then((res) => {
                    this.historyClassList = this.dealWithList(res.data)
                })
            },
            // 整合最近类目、搜索数据
            dealWithList(oddList) {
                let arr = []
                oddList.forEach(item => {
                    let tmp = {
                        label: item.class_name1,
                        value: item.class_id1
                    }
                    if (item.class_id2) {
                        tmp.label = tmp.label + ' > ' + item.class_name2
                        tmp.value = tmp.value + '|' + item.class_id2
                    }
                    if (item.class_id3) {
                        tmp.label = tmp.label + ' > ' + item.class_name3
                        tmp.value = tmp.value + '|' + item.class_id3
                    }
                    arr.push(tmp)
                })
                return arr
            },
            // 获取商品类目
            getCategoryList() {
                adminGoodGetCategory().then((res) => {
                    this.categoryList = res.data
                    this.getLevel23CategoryList()
                })
            },
            // 筛选最近类目
            changeHistoryClass(val) {
                let valTmp = val.split('|')
                let currentTmp = this.historyClassList.find(item => {
                    let itemTmp = item.value.split('|')
                    if (valTmp.length === 2) {
                        if (Number(valTmp[0]) === Number(itemTmp[0]) && Number(valTmp[1]) === Number(itemTmp[1])) {
                            return item
                        }
                    } else if (valTmp.length === 3) {
                        if (Number(valTmp[0]) === Number(itemTmp[0]) && Number(valTmp[1]) === Number(itemTmp[1]) && Number(valTmp[2]) === Number(itemTmp[2])) {
                            return item
                        }
                    }
                })
                this.dealWithShowCate(val, currentTmp.label)
            },
            // 整合处理最近使用类目选择，搜索选择 显示对应类目
            dealWithShowCate(val, label) {
                let arrTmp = val.split('|')
                let labelTmp = label.split(' > ')
                this.class_id1 = Number(arrTmp[0])
                this.class_name1 = labelTmp[0]
                if (arrTmp.length > 0) {
                    this.class_id2 = null
                    this.class_id3 = null
                    this.class_name2 = ''
                    this.class_name3 = ''
                }
                if (arrTmp.length > 1) {
                    this.class_id3 = null
                    this.class_name3 = ''
                    this.class_id2 = Number(arrTmp[1])
                    this.class_name2 = labelTmp[1]
                }
                if (arrTmp.length > 2) {
                    this.class_id3 = Number(arrTmp[2])
                    this.class_name3 = labelTmp[2]
                }
                this.getLevel23CategoryList()
            },
            // 编辑的时候默认显示二三级分类, 并跳到指定位置
            getLevel23CategoryList() {
                if (this.class_id1) {
                    let level1 = this.categoryList.find(item => {
                        if (this.class_id1 === item.id) {
                            // 高亮元素跳到指定位置
                            this.$nextTick(() => {
                                this.$refs[`level_1_${item.id}`][0].scrollIntoView(false)
                            })
                        }
                        return this.class_id1 === item.id
                    })
                    if (level1) {
                        this.level2CategoryList = level1.children || []
                    }
                }
                if (this.class_id2) {
                    let level2 = this.level2CategoryList.find(item => {
                        if (this.class_id2 === item.id) {
                            this.$nextTick(() => {
                                this.$refs[`level_2_${item.id}`][0].scrollIntoView(false)
                            })
                        }
                        return this.class_id2 === item.id
                    })
                    if (level2) {
                        this.level3CategoryList = level2.children || []
                    }
                }
                if (this.class_id3) {
                    this.level3CategoryList.forEach(item => {
                        if (this.class_id3 === item.id) {
                            this.$nextTick(() => {
                                this.$refs[`level_3_${item.id}`][0].scrollIntoView(false)
                            })
                        }
                    })
                }
            },
            // 选择一级二级三级的时候，对应二级三级列表
            changeLevel(val, level) {
                if (level === 1) {
                    this.level3CategoryList = []
                    this.class_id2 = null
                    this.class_id3 = null
                    this.class_name2 = ''
                    this.class_name3 = ''
                    this.class_id1 = val.id
                    this.class_name1 = val.name
                    this.level2CategoryList = val.children || []
                }
                if (level === 2) {
                    this.class_id3 = null
                    this.class_name3 = ''
                    this.class_id2 = val.id
                    this.class_name2 = val.name
                    this.level3CategoryList = val.children || []
                }
                if (level === 3) {
                    this.class_id3 = val.id
                    this.class_name3 = val.name
                }
            },
            // 提交
            nextBtn() {
                if (this.categoryList.length > 0) {
                    if (!this.class_id1) {
                        return this.$message.warning('请选择一级分类')
                    }
                }
                if (this.level2CategoryList.length > 0) {
                    if (!this.class_id2) {
                        return this.$message.warning('请选择二级分类')
                    }
                }
                if (this.level3CategoryList.length > 0) {
                    if (!this.class_id3) {
                        return this.$message.warning('请选择三级分类')
                    }
                }
                let params = {
                    class_id1: this.class_id1,
                    class_id2: this.class_id2,
                    class_id3: this.class_id3,
                }
                adminGoodStepOne(params).then((res) => {
                    let tmp = null
                    if (this.class_id3) {
                        tmp = this.class_id3
                    } else {
                        tmp = this.class_id2
                    }
                    this.$message.success(res.msg)
                    if (this.$route.query.id){
                        this.$router.push({
                            path: '/student/trainCenter/onlineShopManagement/StudentAddGoods',
                            query: {
                                g_id: this.goodId,
                                category_id: tmp,
                                exam_module_id:this.examModuleId,
                                opId: this.$route.query.id,
                                course_id: this.$route.query.course_id,
                            }
                        })
                    }else {
                        this.$router.push({
                            path: '/student/examing/onlineShopManagement/StudentAddGoods',
                            query: {
                                g_id: this.goodId,
                                category_id: tmp,
                                exam_module_id:this.examModuleId,
                            }
                        })
                    }
                })
            },
            // 搜索
            searchBtn() {
                if (!this.searchValue) {
                    return this.$message.warning('请输入搜索关键词')
                }
                let params = {}
                if (this.searchValue) {
                    params.search = this.searchValue
                }
                adminGoodGetCategory(params).then((res) => {
                    if (res.data.length > 0) {
                        this.$message.success(res.msg)
                        this.searchList = this.dealWithList(res.data)
                        this.showSearchBox = true
                    } else {
                        this.$message.warning('未搜索到该类目')
                    }
                })
            },
            // 修改搜索选择
            changeSearchClass(label, val) {
                this.searchValue = label
                this.showSearchBox = false
                this.dealWithShowCate(val, label)
            },
            // 点击空白地方关闭搜索列表
            hideSearchBox() {
                this.showSearchBox = false
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .big-title {
            font-size: 24px;
            margin: 30px 0 0 20px;
            line-height: 1;
        }
        .main-content {
            padding: 30px 60px 100px;
            .history-wrapper {
                & > .text {
                    font-size: 16px;
                    margin-right: 8px;
                }
            }
            .category-wrapper {
                margin-top: 35px;
                margin-bottom: 20px;
                padding: 20px 30px;
                background: #F6F6F6;
                border: 1px solid #eee;
                .search-wrapper {
                    width: 350px;
                    position: relative;
                    .search-list {
                        position: absolute;
                        left: 0;
                        top: 40px;
                        width: 350px;
                        height: 200px;
                        background: #fff;
                        border: 1px solid #DCDCDC;
                        box-sizing: border-box;
                        z-index: 9999;
                        .search-item {
                            line-height: 32px;
                            padding: 0 10px;
                            cursor: pointer;
                            transition: all .3s;
                            &:hover {
                                color: #2DC079;
                            }
                        }
                    }
                    .no-data {
                        color: #999;
                        text-align: center;
                        margin-top: 80px;
                    }
                }
                .category-level {
                    margin: 20px 0;
                    display: flex;
                    .level-item {
                        width: 398px;
                        /*height: 470px;*/
                        height: 418px;
                        background: #fff;
                        margin-left: 16px;
                        border: 1px solid #E5E5E5;
                        &:first-child {
                            margin-left: 0;
                        }
                        .cate-item {
                            padding: 0 25px 0 15px;
                            line-height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer;
                            .item-left {
                                width: 1%;
                                flex: 1;
                            }
                            .iconfont {
                                color: #9CA7B6;
                            }
                            &.current {
                                background: #E7F6EF;
                            }
                        }
                    }
                }
                .category-choose {
                    color: #666;
                    .text {
                        color: #999;
                    }
                    .value {
                        &:after {
                            content: ' > ';
                        }
                        &:last-child {
                            &:after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
</style>